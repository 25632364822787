/*TEMP HOME PAGE STYLING */


.home #pageHeader  {
    /* overflow: hidden; */
    position: relative;
    left: unset;
    right: unset;
    width: 100%;
    aspect-ratio: 3;
    aspect-ratio: 2.6;
    box-shadow: 0 0 15px rgba(0,0,0,0.35);
}

    @media screen and (max-width: 1200px) {
        .home #pageHeader {
            aspect-ratio: 2;
        }
    }


    .home #pageHeader::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 25%;
        z-index: 2;
        background: linear-gradient(to bottom, rgb(0, 0, 0) 0%, rgba(0,0,0,0) 100%);
    }

    .home #pageHeader .background-video-wrapper {
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 1;
        pointer-events: all; /* for playback on mobile */
    } 

        .home #pageHeader .background-video {
            position: absolute;
            width: 100%;
            z-index: 1;
            top: 50%;
            transform: translateY(-50%);
            object-fit: cover;
        }

    .home #pageHeader #headerTop {
        height: 100%;        
        grid-template-rows: min-content auto;
        grid-template-columns: 1fr 300px 1fr;
    }

        @media screen and (min-width: 769px) {
            .home #pageHeader #headerTop #contactAResearcher {
                background-color: transparent;
                box-shadow: none;
            }
            .home #pageHeader #headerTop #contactAResearcher:hover {
                background-color: var(--hf-accent-color);
            }            
        }

        .home #headerTop .homeSearchBar {
            grid-row: 2;
            grid-column: 1 / 4;
        }

        .home #pageHeader #headerTop .homeSearchBar {
            background-color: var(--hf-background-color);
            border-radius: var(--hf-border-radius);
            max-width: 65%;
            height: min-content;
            padding: var(--hf-standard-margin);
            box-shadow: 3px 3px 10px rgba(0,0,0,0.5);
            /*
            margin: var(--hf-standard-margin) auto calc(var(--hf-standard-margin) * 2.5);
            align-self: center;
            */
            justify-self: center;
            transition: var(--hf-standard-transition);

            align-self: end;
            margin: var(--hf-standard-margin) auto calc(var(--hf-standard-margin) * 1.5);
        }

            @media screen and (min-width: 1920px) {
                .home #pageHeader #headerTop .homeSearchBar { margin: var(--hf-standard-margin) auto calc(var(--hf-standard-margin) * 5); }
            }

        .home #videoWindow #pageHeader:not(.fixed) #headerTop .homeSearchBar {            
            margin-right: var(--hf-next-prev-width);
            width: calc(100% - var(--hf-next-prev-width) + (2 * var(--hf-standard-margin)));
        }

            .home #videoWindow #pageHeader:not(.fixed) #headerTop .homeSearchBar .searchBar { width: auto; }

        .home #headerTop h1 { display: none; }

        .home #pageHeader #headerTop h1 {
            display: block;
            font-size: 18px;
            margin: var(--hf-standard-margin) var(--hf-standard-margin) calc(var(--hf-standard-margin) / 2);
            font-style: italic;
            font-weight: 500;
            text-align: center;
        }

.home #pageContent {
    padding-top: calc(var(--hf-standard-margin) * 1.5);
}

/* .home #pageContent .results .result span.thumb { filter: contrast(120%); } */ /* this didn't take effect until after a hover event (?) */
.home #pageContent .results { filter: contrast(120%); }

/* to avoid horizontal scrollbars at narrow widths due to the <video> element's overscan transform */
/* also resolves issue of thumb sometimes bleeding out below the shadow overlay by 1px             */
.home #pageContent .results .resultLink {
    /* overflow: hidden; */ /* overflow caused aspect-ratio to be totally ignored in Safari */ 
    clip-path: inset(0); /* in place of overflow:hidden (which we can't use in Safari), to compensate for the .info bleed */                         
                         /* putting this on the parent .result blocks its drop-shadow */
    display: block;
    /* height: 100%; /* this breaks everything in iOS when wide enough for more than one thumb (landscape) */
    position: relative;
}



    @media screen and (max-width: 768px) {

        .home #pageHeader {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: min-content auto;
            aspect-ratio: unset;
            background-color: var(--hf-background-color);
            justify-items: start;
            padding: 0;
        }

        .home #pageHeader #headerTop {
            height: auto;
            grid-row: 1;
            background-color: var(--hf-background-color);
        }

        .home #pageHeader #headerTop .homeSearchBar {
            margin: 0;
            max-width: 100%;
            border-radius: 0;
            box-shadow: none;
            align-self: start;
            padding-top: 0;
            padding-bottom: 0;
            border-top: var(--hf-mobile-header-border);
            position: relative;
        }       

        .home #pageHeader .background-video {
            position: relative;
            grid-row: 2;
            overflow: hidden;
            aspect-ratio: 2.5;
        }

        .home #pageHeader #headerTop .searchBar {
            position: absolute;
            margin: 0;
            top: 210px;
            left: var(--hf-standard-margin);
            right: var(--hf-standard-margin);
            grid-column: 1 / 6;
            background-color: var(--hf-background-color);
            border-radius: var(--hf-border-radius);
        }

        .home #pageHeader .background-video-wrapper {
            position: relative;
            width: 100%;
        }

        .home #pageHeader #headerTop h1 {
            margin: calc(var(--hf-standard-margin) / 1.5) 0 calc(var(--hf-standard-margin) * 0.75);
            font-weight: 300;
        }

    }
    
    @media screen and (max-width: 632px) {
        .home #pageHeader .background-video {
            aspect-ratio: unset;
            height: 250px;
            height: 50vh;
            width: 100%;
        }
        .home #pageHeader #headerTop .searchBar {
            top: 40vh;
            /* top: 30vh; */
        }
        .home #pageContent {
            padding-top: calc(var(--hf-standard-margin) * 0.5);
        }
        .home .binContents.searchContainer .masonry { gap: 15px; }
    }

.home .masonry::after { flex-grow: 8; } /* tighter final row wrapping for the home page */


#youTubeBanner {
    /*
    margin: -100px calc(var(--hf-standard-margin) * -1) 0;
    height: 36vw;
    min-height: 460px;
    */
    position: relative;
    /* background-position-x: 7vw; */
    background-position-x: right;
    background-position-y: bottom -5vw;
    background-repeat: no-repeat;
    background-image: url('homePageYouTubeBG.png');
    /* background-size: cover; */
    background-size: 50%;
    padding-top: 100px;
    padding-bottom: var(--hf-standard-margin);

    margin: -130px calc(var(--hf-standard-margin) * -1) 0;
    /*
    height: 31vw;
    min-height: 420px;    
    background-position-y: 3em;
    */
}

    /* to match the contraction of #pageContent's padding */
    @media screen and (max-width: 632px) {
        #youTubeBanner {
            margin-left: -10px;
            margin-right: -10px;
        }
    }
    
    /*
    @media screen and (max-width: 632px) {
        #youTubeBanner { margin: -100px -10px 0; }
    }
    */

.home #pageContent { padding-bottom: 0; }
.home .binContents.searchContainer {
    position: relative;
    z-index: 1;
}

#youTubeBanner .subscribers {
    margin-left: 7vw;
    /* font-size: 36px; */
    font-weight: 500;
    /*
    position: absolute;
    bottom: 16%;
    top: 100px;
    */
    position: relative;
    margin-top: 100px;
    padding-left: 26vw;
    height: 11vw;
    margin-bottom: calc(var(--hf-standard-margin) * 1.5);

    display: flex;
    align-items: end;
    text-decoration: none;
    color: var(--hf-faint-text-color);
    transition: color 0.2s ease-in-out;

    background-repeat: no-repeat;
    background-image: url('homePageYouTube.png');
    background-size: contain;

    font-size: 2vw;
}

    @media screen and (max-width: 1024px) {
        #youTubeBanner {
            background-position-x: right -7vw;
        }
        #youTubeBanner .subscribers {
            padding-left: 33vw;
            height: 14vw;
        }
    }

    @media screen and (max-width: 768px) {
        #youTubeBanner .subscribers {
            margin-top: 10vw;
            margin-bottom: var(--hf-standard-margin);
        }
    }

    @media screen and (max-width: 660px) {
        #youTubeBanner {
            background-size: 75%;
            background-position-x: right -13vw;
        }
        #youTubeBanner .subscribers {
            padding-left: 47vw;
            height: 20vw;
            margin-left: calc(var(--hf-standard-margin) * 1.5);
            font-size: 4vw;
        }
    }

    @media screen and (max-width: 480px) {
        #youTubeBanner .subscribers {
            margin-top: 13vw;
            margin-bottom: calc(var(--hf-standard-margin) / 2);
        }
    }

    #youTubeBanner .subscribers:hover { color: var(--hf-strong-color); }



/* END TEMP HOME PAGE STYLING */
